.nav-Item {
    padding: 15px;
}
.detect-mobile {
    .phone-container-dashboard {
        position: relative;
        padding: 15px 10px;
    }
    .NavBar-box {
        margin-bottom: 15px;
        padding: 5px;
        background: #ffffff;
        border-radius: 15px;

        .ShoppingCart-Icon {
            font-size: 23px;
            margin-right: 20px;
        }
        .MenuOutlined-Icon {
            font-size: 23px;
        }
    }
    .collapse-phone-box {
        border-radius: 15px;
        margin-bottom: 15px;
        background: #ffffff;
        border: 0;

        .ant-collapse-header {
            padding: 15px 20px 15px 20px;
        }

        .ant-collapse-item {
            border: 0;
        }

        .ant-collapse-content {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
    .order-box {
        border-radius: 15px;
        margin-bottom: 15px;
        background: #ffffff;

        .ant-descriptions-view {
            border:0;
            border-radius: 15px;
        }
    }
    .phone-box {
        padding: 20px 15px;
        border-radius: 15px;
        margin-bottom: 15px;
        background: #ffffff;
    }
    .ant-card-bordered {
        border-radius: 15px;
    }

    .auth-forms-container {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .auth-forms-layout .svg-bottom {
        bottom: -220px;
        left: -75px;
    }
    .auth-forms-layout .svg-top {
        top: -1100px;
        right: -150px;
    }
    .auth-forms-container footer {
        display: block;
        margin-top: 40px;
    }
    .auth-forms-container footer p:first-child {
        padding-bottom: 15px;
    }
    .auth-forms-container footer p {
        width: 100%;
        display: block;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        display: inline-block!important;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal {
        display: flex!important;
        padding-bottom: 15px!important;
    }
    .ant-steps-item-title {
        position: relative;
        display: inline-block;
        padding-right: 16px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 16px;
        line-height: 32px;
    }
    .ant-steps-item-title::after {
        content: '';
        position: absolute;
        top: 16px;
        left: 100%;
        display: block!important;
        width: 9999px;
        height: 1px;
        background: #e8e8e8;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        margin-right: 0;
    }
    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
        display: none!important;
    }
    .nomenclature-card {
        .ant-card-body div.title {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
        }
        .nomenclature-card-info {
            display: none;
        }
    }
}
