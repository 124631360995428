body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.text-center {
    text-align: center!important;
}
.ant-layout {
    background: rgb(253, 255, 255);
}
.h100vh {
    height: 100vh!important;
}
.mh-100vh {
    min-height: 100vh!important;
}
.wp-100 {
    width: 100%;
}
.hide {
    display: none!important;
}
.c-pointer {
    cursor: pointer!important;
}
.hide {
    display: none!important;
}
// START --- Формы авторизации
.auth-forms-layout {
    overflow: hidden;
    background-color: #DBE0F9;
    position: relative;
}
.auth-forms-layout .svg-top {
    position: absolute;
    top: -900px;
    right: -300px;
    transition: all .3s ease 0s;
}
.auth-forms-layout .svg-bottom {
    position: absolute;
    bottom: -200px;
    left: -35px;
    transition: all .3s ease 0s;
}
.auth-forms-container {
    padding: 20px 40px;
    background-color: #ffffff;
    border-radius: 20px;
    z-index: 1;
    transition: all .3s ease 0s;
}
.auth-forms-container footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 60px;
    transition: all .3s ease 0s;
}
.auth-forms-container footer p {
    margin: 0;
    font-weight: 100;
    transition: all .3s ease 0s;
}
.auth-forms-container footer p a {
    color: #6065D9;
    text-decoration: none;
}
.auth-forms-container header {
    margin-bottom: 30px;
    transition: all .3s ease 0s;
}
.auth-forms-container header .logo img {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.auth-forms {
    transition: all .3s ease 0s;
    height: auto;
}
.auth-forms .login-form-button {
    padding-right: 40px;
    padding-left: 40px;
}
.auth-forms .login-form-button:enabled {
    background-color: $danger-color;
    color: #ffffff;
    border-color: $danger-color;
}
// END --- Формы авторизации

// START --- Основных страниц
.container {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    z-index: 1;
    transition: all .3s ease 0s;
    min-height: calc(100vh - 80px);
    margin: 40px;
}
.mutated-button {
    border-radius: 20px;
    // padding-right: 40px;
    // padding-left: 40px;
}
// END --- Основных страниц

// START --- Создание заказа
.nomenclature-card {
    transition: all .3s ease 0s;

    .ant-card-body {
        padding: 10px 15px;
    }
    .ant-card-cover {
        background: rgba(219, 219, 217, .6);
    }
    .ant-card-cover img {
        width: 100%;
    }
    .box-image-in-card {
        display: table-cell;
        vertical-align: middle;
        position: relative;
    }
    .ant-card-body div.box {
        display: table-cell;
        vertical-align: middle;
    }
    .ant-card-body div.title {
        text-align: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: keep-all;
        line-break: strict;
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;

    }
    .ant-card-body div.amount {
        text-align: center;

        .ant-statistic-content {
            font-size: 20px;

            .ant-statistic-content-suffix {
                font-size: 14px;
            }
        }
    }
    .ant-card-bordered {
        border-radius: 0;
        transition: all .3s ease 0s;
        overflow: hidden;
        position: relative;
    }

    .nomenclature-card-info {
        transition: all 0.3s ease 0s;
        position: absolute;
        top: -50px;
        right: -100%;
        width: 100%;
        display: block;
        text-align: right;
        height: 61px;
        line-height: 50px;
        padding-right: 20px;
        font-size: 16px;
        color: #ffffff;
        background: url('../images/authSvgTop.svg');
        background-size: 117%;
        background-position-x: 35px;
        background-position-y: -299px;
        background-repeat: no-repeat;

    }
}
.nomenclature-card:hover {
    .pricing-box {
        opacity: 1;
        transition: .3s;
    }
}
.nomenclature-card.added {
    .ant-card-bordered {
        border-radius: 15px;
        border: 1px solid rgba(41, 45, 210, .3);
    }
    .nomenclature-card-info {
        top: 0;
        right: 0;
    }
}
.ant-page-header {
    border: 1px solid rgb(235, 237, 240)
}
.box-input-list {
    text-align: center;

    .ant-typography {
        margin-top: 0.5em;
    }
}
.order-filter-button {
    margin-top: 40px;
}
.pricing-box {
    table {
        width: 100%;

        thead {
            tr {
                td {
                    height: 30px;
                    width: 50%;
                    color: #ffffff;
                    font-weight: 500;
                    background-color: #1890ff;
                    position: relative;
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                td {
                    height: 30px;
                    color: #343739;
                    background-color: #ffffff;
                    text-align: center;
                    font-weight: 500;
                }
            }
            tr:nth-child(even) td {
                background-color: #e8e8e8;
            }
        }
    }

    .ant-input[disabled] {
        color: #333333;
    }
}
.footer-table {
    display: inline-block;
    width: 100%;
    text-align: right;
}
.ant-table-footer,
.ant-table-small .ant-table-thead > tr > th {
    background-color: rgba(219, 219, 217, .3);
}
