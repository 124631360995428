@import '~antd-mobile/dist/antd-mobile.css';
@import '~antd/dist/antd.css';
@import './css/spacings.css';

$primary-color: rgb(50, 146, 225);
$danger-color: rgb(225,31,38);

@import './scss/main.scss';
@import './scss/main_phone.scss';

.none {
    display: none!important;
}
.text-rigrt {
    text-align: right!important;
}
.ant-input-group .ant-input[disabled] {
    color: #333333;
}

.select-like-ant{
    padding-right: 10px;
}